import * as React from 'react';
import { graphql } from 'gatsby';

import { ServiceAreaPagesQuery } from '../../graphql-types';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import { RichText } from '../components/RichText';
import SEO from '../components/seo';

interface ServiceAreaPagesProps {
  data: ServiceAreaPagesQuery;
}

export const query = graphql`
  query ServiceAreaPages($id: String!) {
    contentfulServiceArea(id: { eq: $id }) {
      id
      slug
      title
      body {
        json
      }
    }
  }
`;

export default ({ data }: ServiceAreaPagesProps) => {
  const l = data.contentfulServiceArea;
  const bodyJson = l?.body?.json;
  const title = l?.title as string;

  return (
    <Layout>
      <SEO title={title} />

      <Section>
        <Container>
          <h1>{title}</h1>

          <div>
            <RichText json={bodyJson} />
          </div>
        </Container>
      </Section>
    </Layout>
  );
};
